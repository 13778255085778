<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >  
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        REQUIRED DOCUMENTS
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        Please upload a scanned or picture of the required documents
                        in the appropriate fields below
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

        <v-row 
            align="center"
            justify="center"
            v-for="document in documents"
            :key="document.label"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >                  
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-file-input
                            :label="document.label"
                            :placeholder="document.placeholder"
                            prepend-icon="mdi-paperclip"                            
                            :ref="document.ref"
                            v-model="files[document.model]"
                            clearable
                            @change="handleFileUpload(document.model)"
                        >
                            <template
                                v-slot:selection="{ text }"
                            >
                                <v-chip
                                    small
                                    label
                                    color="primary"
                                >
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                        <div >
                            
                            <v-progress-linear
                                v-model="uploadProgress[document.model]"
                                color="light-blue"
                                height="25"
                                reactive
                                z-index="10"                                
                            >
                                <strong>{{ uploadProgress[document.model] }} %</strong>
                            </v-progress-linear>
                               
                            <v-row
                                justify="end"
                                class="mt-3"
                                no-gutters
                                v-if="messages[document.model]"
                            >
                                <v-chip
                                    label
                                >
                                    {{ messages[document.model] }}
                                </v-chip>
                            </v-row>
                            
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Documents',
    components: {
        Layout,
    },
    data: () => ({
        documents: [
            {
                label: 'Birth Certificate', 
                placeholder: 'Upload your birth certificate', 
                model: 'birth_certificate',
                ref: 'birth_certificate',
            },
            {
                label: 'Immunization Card', 
                placeholder: 'Upload your immunization card', 
                model: 'immunization_card',
                ref: 'immunization_card',
            },
            {
                label: 'SEA Slip', 
                placeholder: 'Upload your SEA results slip', 
                model: 'sea_slip',
                ref: 'sea_slip',
            },
        ],
        files:{
            birth_certificate: null,
            immunization_card: null,
            sea_slip: null,
        },
        uploadProgress: {
            birth_certificate: 0,
            immunization_card: 0,
            sea_slip: 0,
        },        
        messages: {
            birth_certificate: '',
            immunization_card: '',
            sea_slip: ''
        },
        studentRecord: {},
    }),
    mounted: function (){
        this.copyStudentRecord();
        console.log(this.studentRecord);
    },
    computed: {
        ...mapGetters({
            getBirthCertificate: 'form/getBirthCertificate',
            getUserId: 'auth/getUserId',
            getStudentRecord: 'auth/getStudentRecord',
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
        }),
        themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
        }, 
    },
    watch: {
        'files.birth_certificate': {
            handler(val){
                console.log(val);
                if(!val){
                    this.messages.birth_certificate = null;
                    this.uploadProgress.birth_certificate = 0;
                }
            }
        },
        'files.immunization_card': {
            handler(val){
                console.log(val);
                if(!val){
                    this.messages.immunization_card = null;
                    this.uploadProgress.immunization_card = 0;
                }
            }
        },
        'files.sea_slip': {
            handler(val){
                console.log(val);
                if(!val){
                    this.messages.sea_slip = null;
                    this.uploadProgress.sea_slip = 0;
                }
            }
        },
    },
    methods: {        
        handleFileUpload(model){ 
            switch(model){
                case 'birth_certificate':
                    this.uploadBirthCertificate();
                    break;
                case 'immunization_card':
                    this.uploadImmunization();
                    break;
                case 'sea_slip':
                    this.uploadSeaSlip();
                    break;
            }           
        },
        uploadBirthCertificate(){
            if(this.files.birth_certificate){
                let formData = new FormData()
                formData.append('file', this.files.birth_certificate);
                formData.append('id', this.getUserId);
                formData.append('doc_name', 'birth_certificate');
                this.setBirthCertificate(formData);                
                this.submitBirthCertificate().then(res => {
                    console.log(res.data);
                    this.messages.birth_certificate = res.data.success;
                    this.uploadProgress.birth_certificate = 100;
                    this.recordFileUploaded('file_birth_certificate', res.data.file);
                })
                .catch((err) => {
                    this.birthCertificateProgress = 0;
                    this.messages.birth_certificate = err;
                })
            }
        },
        uploadImmunization(){
            if(this.files.immunization_card){
                let formData = new FormData()
                formData.append('file', this.files.immunization_card);
                formData.append('id', this.getUserId);
                formData.append('doc_name', 'immunization_card');
                this.setImmunization(formData);                             
                this.submitImmunization().then(res => {
                    console.log(res.data);
                    this.messages.immunization_card = res.data.success;
                    this.uploadProgress.immunization_card = 100;
                    this.recordFileUploaded('file_immunization_card', res.data.file);
                })
                .catch((err) => {
                    this.birthCertificateProgress = 0;
                    this.messages.immunization_card = err;
                })
            }
        },
        uploadSeaSlip(){
            if(this.files.sea_slip){
                let formData = new FormData()
                formData.append('file', this.files.sea_slip);
                formData.append('id', this.getUserId);
                formData.append('doc_name', 'sea_slip');
                this.setSeaSlip(formData);                
                this.submitSeaSlip().then(res => {
                    console.log(res.data);
                    this.messages.sea_slip = res.data.success;
                    this.uploadProgress.sea_slip = 100;
                    this.recordFileUploaded('file_sea_slip', res.data.file);
                })
                .catch((err) => {
                    this.birthCertificateProgress = 0;
                    this.messages.sea_slip = err;
                })
            }
        },
        ...mapActions({
            submitBirthCertificate: 'form/uploadBirthCertificate',
            submitImmunization: 'form/uploadImmunization',
            submitSeaSlip: 'form/uploadSeaSlip',
            saveRecord: 'auth/saveRecord',
        }),
        ...mapMutations({
            setBirthCertificate: 'form/setBirthCertificate',
            setImmunization: 'form/setImmunization',
            setSeaSlip: 'form/setSeaSlip',
            setStudentRecord: 'auth/setStudentRecord',           
        }),
        copyStudentRecord(){
            this.studentRecord = {...this.getStudentRecord};
        },
        recordFileUploaded(prop, value){
            this.studentRecord[prop] = value;
            this.setStudentRecord(this.studentRecord);
            this.saveRecord();
        }
    }
}
</script>