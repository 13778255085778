<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span class="mr-2 primary--text">Logout</span>
        <v-icon color="primary">mdi-logout</v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            openDialog: 'form/setDialog',
            setDialogCard: 'form/setDialogCard',
            setDialogProgress: 'form/setDialogProgress',
            setOverlay: 'auth/setOverlay',            
        }),

        async logOut () {            
            //this.openDialog(true);
            //this.setDialogCard(false);
            this.setOverlay(true);            
            await this.signOut();
            console.log("logout complete");
            this.setOverlay(false); 
            this.$router.replace('/')
        }

        
    }
}
</script>