import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: {student_id: null, student_name: null},
        userRecord: null,
        studentRecord: null,
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        newPassword: '',
        displayPasswordChanged: false,
        validStudentID: false,
        userInitials: '', 
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUser (state) {
            return state.user
        },

        getUserId (state) {
            return state.user.student_id
        },

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },

        getStudentRecord(state){
            return state.studentRecord
        },

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },

        getStudentIDValidation(state){
            return state.validStudentID
        },

        getUserInitials(state){
            return state.userInitials
        }
    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUser (state, value) {
            state.user = value
        },

        setUserId (state, value){
            state.user.student_id = value;
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },

        setStudentRecord(state, value){
            state.studentRecord = value
        },

        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },

        setStudentIDValidation(state, value){
            state.validStudentID = value
        },

        setUserInitials(state, value){
            state.userInitials = value
        }
    },

    actions: {
        async signIn ({ commit, dispatch }, credentials){ 
            commit('setOverlay', true);
            try{
                await axios.get('/sanctum/csrf-cookie')
                console.log("authenticating login credentials");
                await axios.post('/api/login-student', credentials);
                // console.log(response);
                console.log('authenticated');
                commit('setUser', credentials);
                await dispatch('student');                
                commit('setAuthenticated', true);
                //console.log(getters.getAuthenticated);                
            } catch(error){
                console.log(error.response);
                console.log(error.response);                        
                commit('setLoginErrors', error.response.data.message);
                commit('setAuthenticated', false);
                commit('setOverlay', false); 
            }    
            
            // await dispatch('user');
            
            
            
        },

        async student({ getters, commit }){
            let url = '/api/student-record/' + getters.getUserId;
            //console.log(`url: ${url}`);
            try{
                const { data } = await axios.get(url);
                commit('setUserRecord', data);
                let first_initial = data.first_name[0];
                let last_initial = data.last_name[0]
                //console.log(response.data[0].last_name[0]);                
                commit('setUserInitials', first_initial + last_initial);                    
                //commit('setStudentRecord', response.data[0])
            } catch (error) {
                console.log(error);
                commit('setUser', null)
            }            
        },

        async user({ getters, commit }){
            console.log("getting user information...");
            let url = '/api/user/' + getters.getUserId;
            console.log(url);
            await axios.get(url)
                .then(response => {
                    console.log(response);
                    if(response.data.length != 0){
                        commit('setStudentIDValidation', true)
                        commit('setLoginErrors', '');
                        commit('setUserRecord', response.data[0]);
                        if(response.data[0].reset_password == 1){
                            commit('setResetPassword', true);
                        }
                        else commit('setResetPassword', false);  
                    }
                    else{
                        commit('setLoginErrors', 'Student ID not found');
                        commit('setStudentIDValidation', false)
                        commit('setAuthenticated', false);
                        commit('setOverlay', false);                      
                    }                  
                })
        },

        async changePassword({ getters }){
            //console.log(getters.getNewPassword);
            let url = '/api/change-password';
            return await axios.post(url, {
                "student_id" : getters.getUserId,
                "password" : getters.getNewPassword,
            })
            
        },

        async signOut({commit}){                       
            await axios.post('/logout')
                    .then(response => {
                        console.log(response);
                    })
            return commit('setAuthenticated', false); 
        },
        
        async saveRecord({getters}){
            //console.log(getters.getStudentRecord);
            let studentRecord = getters.getStudentRecord;            
            return axios.post('/api/student', studentRecord);
        },

       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },

       async getStudents(){
           try{
                return await axios.get('/api/students')
           }
           catch (error) {
                console.log(error);
           }
           
       },

       async changeResetPassword({ commit, getters }, resetPassword){
            commit('setOverlay', true);
            await axios.post('/api/change-reset-password',{
                "student_id" : getters.getUserId,
                "reset_password" : resetPassword,
            });
            commit('setResetPassword', resetPassword);
            commit('setDisplayPasswordChanged', true);
            commit('setOverlay', false);
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       }
    }
}