<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card
            v-show="dialogCard"
        >
            <v-card-title :class="titleClass">
                {{ title }}
            </v-card-title>
            <v-card-text :class="textColor">
                {{ text }}
            </v-card-text>
            <v-card-text
                v-show="success"
            >
                <v-row
                    align="center"
                    justify="center"
                >
                    <div class="sa">
                    <div class="sa-success">
                    <div class="sa-success-tip"></div>
                    <div class="sa-success-long"></div>
                    <div class="sa-success-placeholder"></div>
                    <div class="sa-success-fix"></div>
                    </div>
                    </div>
                </v-row>
            </v-card-text>
            <v-card-text
                v-show="warning"
            >
                <v-row
                    align="center"
                    justify="center"
                >
                    <div class="sa">
                    <div class="sa-warning">
                    <div class="sa-warning-body"></div>
                    <div class="sa-warning-dot"></div>
                    </div>
                    </div>
                </v-row>
            </v-card-text> 
            <v-card-text>
                <v-list>
                    <v-list-item-group
                        color="red lighten-3"
                        v-model="item"
                    >
                        <v-list-item
                            v-for="(error, i) in errors"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon color="red">mdi-alert-circle</v-icon>                                
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="red--text" 
                                    v-text="error"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeDialog(false)"
                >
                    Ok                
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card
            v-show="dialogProgress"
            class="pa-5"
            color="transparent"                        
            elevation="0"
        >
            <v-row
                align="center"
                justify="center"
            >
            <v-progress-circular
                
                indeterminate
                size="64"
            ></v-progress-circular>
            </v-row>
        </v-card>       

    </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'FormDialog',

    data: () => ({
        item: 1,
    }),
    
    computed: {
        ...mapGetters({
            dialog: 'form/getDialog',
            dialogCard: 'form/getDialogCard',
            title: 'form/getDialogTitle',
            text: 'form/getDialogText',
            icon: 'form/getDialogIcon',
            dialogProgress: 'form/getDialogProgress',
            errors: 'form/getValidationErrors',
            textColor: 'form/getDialogTextColor',
            success: 'form/getDialogSuccessIcon',
            warning: 'form/getDialogWarningIcon',
        }),
        titleClass(){
            return 'Headline ' + this.textColor;
        }        
    },
    mounted (){
       //
    },
    methods: {
        ...mapMutations({
            closeDialog: 'form/setDialog',
        })
    }
}
</script>

<style lang="scss">
    .sa {
        width: 140px;
        height: 140px;
        padding: 26px;
        background-color: #fff;
        &-success {
            border-radius: 50%;
            border: 4px solid #A5DC86;
            box-sizing: content-box;
            height: 80px;
            padding: 0;
            position: relative;
            background-color: #fff;
            width: 80px;
            &:after, &:before {
            background: #fff;
            content: '';
            height: 120px;
            position: absolute;
            transform: rotate(45deg);
            width: 60px;
            }
            &:before {
            border-radius: 40px 0 0 40px;
            width: 26px;
            height: 80px;
            top: -17px;
            left: 5px;
            transform-origin: 60px 60px;
            transform: rotate(-45deg);
            }
            &:after {
            border-radius: 0 120px 120px 0;
            left: 30px;
            top: -11px;
            transform-origin: 0 60px;
            transform: rotate(-45deg);
            animation: rotatePlaceholder 4.25s ease-in;
            }
            &-placeholder {
            border-radius: 50%;
            border: 4px solid rgba(165, 220, 134, 0.25);
            box-sizing: content-box;
            height: 80px;
            left: -4px;
            position: absolute;
            top: -4px;
            width: 80px;
            z-index: 2;
            }
            &-fix {
            background-color: #fff;
            height: 90px;
            left: 28px;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            width: 5px;
            z-index: 1;
            }
            &-tip, &-long {
            background-color: #A5DC86;
            border-radius: 2px;
            height: 5px;
            position: absolute;
            z-index: 2;
            }
            &-tip {
            left: 14px;
            top: 46px;
            transform: rotate(45deg);
            width: 25px;
            animation: animateSuccessTip .75s;
            }
            &-long {
            right: 8px;
            top: 38px;
            transform: rotate(-45deg);
            width: 47px;
            animation: animateSuccessLong .75s;
            }
        }
    }
    @keyframes animateSuccessTip {
    0%, 54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
    }
    @keyframes animateSuccessLong {
        0%, 65% {
            width: 0;
            right: 46px;
            top: 54px;
        }
        84% {
            width: 55px;
            right: 0;
            top: 35px;
        }
        100% {
            width: 47px;
            right: 8px;
            top: 38px;
        }
    }
    @keyframes rotatePlaceholder {
        0%, 5% {
            transform: rotate(-45deg);
        }
        100%, 12% {
            transform: rotate(-405deg);
        }
    }

    .sa {
        width: 140px;
        height: 140px;
        padding: 26px;
        background-color: #fff;
        &-warning {
            border-radius: 50%;
            border: 4px solid #F8BB86;
            box-sizing: content-box;
            height: 80px;
            padding: 0;
            position: relative;
            background-color: #fff;
            width: 80px;
            animation: scaleWarning 0.75s infinite alternate;
            &:after, &:before {
            background: #fff;
            content: '';
                    border-radius: 50%;
                    height: 100%;
            position: absolute;
                    width: 100%;
            }
            &:before {
                    display: inline-block;
                    opacity: 0;
                    animation: pulseWarning 2s linear infinite;
            }
            &:after {
                    display: block;
                    z-index: 1;
            }
            &-body {
            background-color: #F8BB86;
            border-radius: 2px;
            height: 47px;
            left: 50%;
            margin-left: -2px;
            position: absolute;
            top: 10px;
            width: 5px;
            z-index: 2;
            animation: pulseWarningIns 0.75s infinite alternate;
            }
            &-dot {
            background-color: #F8BB86;
            border-radius: 50%;
            bottom: 10px;
            height: 7px;
            left: 50%;
            margin-left: -3px;
            position: absolute;
            width: 7px;
            z-index: 2;
            animation: pulseWarningIns 0.75s infinite alternate;
            }
        }
    }
    @keyframes scaleWarning {
        0% {
            transform: scale(1);
        }
        30% {
            transform: scale(1.02);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes pulseWarning {
        0% {
            background-color: #fff;
            transform: scale(1);
            opacity: 0.5;
        }
        30% {
            background-color: #fff;
            transform: scale(1);
            opacity: 0.5;
        }
        100% {
            background-color: #F8BB86;
            transform: scale(2);
            opacity: 0;
        }
    }
    @keyframes pulseWarningIns {
        0% {
            background-color: #F8D486;
        }
        100% {
            background-color: #F8BB86;
        }
    }
</style>