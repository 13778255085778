<template>
    <layout>
         <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >  
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        HEALTH DATA
                    </v-toolbar-title>
                </v-app-bar>                
            </v-card>            
         </v-row>

         <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >  
                <v-app-bar
                    flat
                    color="white"
                >
                    <v-toolbar-title class="body-2 pl-0 text-wrap">
                        Please indicate whether your child was immunized for the following
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in immunizationRecords"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                            v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>                      
         </v-row>

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                    class="pa-6 mt-4" 
                    width="500"
                >  
                <v-app-bar
                    flat
                    color="white"
                >
                    <v-toolbar-title class="body-2 pl-0 text-wrap">
                        Please indicate whether your child suffers from any of the following 
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in healthConditions"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                           v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                    class="pa-6 mt-4" 
                    width="500"
                >  
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in fields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="updateStore"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card> 
        </v-row>                   
    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'HealthData',
    components: {
        Layout,
    },
    data: () => ({
        fields: [
            {label: 'Other Health Condition', type: 'text', model: 'medical_history'},
            {label: 'Serious Illness', type: 'text', model: 'other_illness'},
            {label: 'Allergies', type: 'text', model: 'allergy'},
        ],
        immunizationRecords: [
            {label: 'Hepatitis', model: 'hepatitis'},
            {label: 'Polio', model: 'polio'},
            {label: 'Diphtheria', model: 'diphtheria'},
            {label: 'Tetanus', model: 'tetanus'},
            {label: 'Yellow Fever', model: 'yellow_fever'},
            {label: 'Measles', model: 'measles'},
            {label: 'T.B.', model: 'tb'},
            {label: 'Chicken Pox', model: 'chicken_pox'},
            {label: 'Typhoid', model: 'typhoid'},
            {label: 'Rheumatic Fever', model: 'rheumatic_fever'},
        ],
        healthConditions: [
            {label: 'Poor Eyesight', model: 'poor_eyesight'},
            {label: 'Poor Hearing', model: 'poor_hearing'},
            {label: 'Diabetes', model: 'diabetes'},
            {label: 'Asthma', model: 'asthma'},
            {label: 'Epilepsy', model: 'epilepsy'},
        ],
        studentRecord: {},
        healthRecord: {
            hepatitis: false,
            polio: false,
            diphtheria: false,
            tetanus: false,
            yellow_fever: false,
            measles: false,
            tb: false,
            chicken_pox: false,
            typhoid: false,
            rheumatic_fever: false,
            poor_eyesight: false,
            poor_hearing: false,
            diabetes: false,
            asthma: false,
            epilepsy: false,
        }  
    }),

    mounted: function () {
        this.setStudentRecord();
        this.setHealthRecord();        
    },
    
    computed: {
        ...mapGetters({            
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
        }),
        themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
        },   
    },
        
    methods: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord'
        }),
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord()}
        },
        setHealthRecord(){
            for(const [key, value] of Object.entries(this.healthRecord)){
               //console.log(this.studentRecord[key]);
               //console.log(key);
               console.log(value);
               if(this.studentRecord[key] == 1){
                   this.healthRecord[key] = true;
               }
               else{
                   this.healthRecord[key] = false;
               }                           
           }
           //console.log(this.healthRecord);
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',            
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),
        updateStore(){
            console.log('updating store');
            this.updateStudentRecord(this.studentRecord);
            this.saveRecord()
                .then(response => {
                    console.log(response.status);
                });
        },
        checkBoxUpdate(){
           for(const [key, value] of Object.entries(this.healthRecord)){
               //console.log(`${key}: ${value}`)
               if(value){
                   this.studentRecord[key] = 1;
               }
               else{
                   this.studentRecord[key] = 0;
               }
           }
           this.updateStore();
        }
    }
}
</script>