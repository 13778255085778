<template>
    <v-app>
        <v-app-bar
        app
        color="secondary"
        dark
       
        >
            <div class="d-flex align-center primary--text">
                <v-img                
                class="shrink mr-2"
                contain
                src="../../assets/logo.png"
                transition="scale-transition"
                width="50"
                />
                {{ schoolName}}
                
            </div>

            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                size="36"                
                fab
                dark
                small
                elevation="0"                
            >
            {{ userInitials }}               
            </v-btn>

            <Logout/>
        </v-app-bar>

        <v-main class="grey lighten-5" >            
            <v-container 
                fluid
                class="grey lighten-5"                               
            >                                   
                <div> Welcome {{ userRecord.first_name }} {{ userRecord.last_name}}</div>
                <slot></slot>
                
            </v-container>
            <form-dialog/>                 
        </v-main>
    </v-app> 
    
</template>

<script>
import Logout from '../auth/Logout';
import FormDialog from '../FormDialog';
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {    
    Logout,      
    FormDialog,    
  },

  data: () => ({
     documentTop: true,     
     floatingButton: false,     
  }),

  methods: {
      quickScroll(){
          this.documentTop = !this.documentTop;
          if(this.documentTop){
              window.scrollTo(0, document.body.scrollHeight);
          }
      },
      changePassword(){
          this.$router.push('/registration/change-password');
      }

  },

  computed: {
      activeFab () {
          if(this.documentTop){
              return {color: this.primaryColor + ' ' + this.colorDarken, icon: 'mdi-chevron-down'};
          }
          else{
              return {color: this.primaryColor + ' ' + this.colorDarken, icon: 'mdi-chevron-up'}
          }
      },
      getThemeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
      },
      avatarColor(){
          return this.avatarColor + ' ' + this.avatarDarken;
      },
      ...mapGetters({
          getUser: 'form/getUser',
          schoolName: 'form/getSchoolName',
          primaryColor: 'form/getPrimaryColor',
          colorDarken: 'form/getColorDarken',
          avatarColor: 'form/getAvatarColor',
          avatarDarken: 'form/getAvatarDarken',
          userRecord: 'auth/getUserRecord',
          userInitials: 'auth/getUserInitials',
      })
      
  }
};
</script>

<style scoped>
    .quick-navigation{
        position: fixed;       
    }
</style>
