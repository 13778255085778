import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import form from './form'
import report from './report'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URI

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        form,
        report
    }
})