import Login from './components/auth/Login'
import ChangePassword from './components/auth/ChangePassword'
import StudentData from './components/StudentData'
import FamilyData from './components/FamilyData'
import HealthData from './components/HealthData'
import OtherData from './components/OtherData'
import Documents from './components/Documents'
import Complete from './components/Complete'
import TermReport from './components/TermReport'

//import Logout from './components/auth/Logout'

const routes = [
  { 
      path: '/',
      name: 'login', 
      component: Login,
      meta: {
        requiresVisitor: true,
      }
  },  
  {
    path: '/registration/student-data',
    name: 'studentData',
    component: StudentData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/term-report',
    name: 'termReport',
    component: TermReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/family-data',
    name: 'familyData',
    component: FamilyData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/health-data',
    name: 'healthData',
    component: HealthData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/other-data',
    name: 'otherData',
    component: OtherData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/documents',
    name: 'documents',
    component: Documents,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/complete',
    name: 'complete',
    component: Complete,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/change-password',
    name: 'changePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '*',
  //   component: NotFoundComponent
  // }  
]

export default routes