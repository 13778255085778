import axios from 'axios'

export default{
    namespaced: true,

    state: {
        studentDataValidated: false,
        familyDataValidated: false,
        healthDataValidated: false,
        otherDataValidated: false,
        documentsAddedValidated: false,
        formValidated: false,
        currentStep: 1,
        towns: [],
        religions: {},
        ethnicGroups: {},
        house: {},
        primarySchools: {},
        dialog: false,
        dialogCard: false,
        dialogProgress: false,
        dialogTitle: null,
        dialogText: null,
        dialogIcon: null,
        dialogTextColor: null,
        dialogSuccessIcon: false,
        dialogWarningIcon: false, 
        validationErrors: [],
        studentDataMounted: 0,
        familyDataMounted: 0,
        heathDataMounted: 0,
        otherDataMounted: 0, 
        user: null,
        birthCertificate: null,        
        immunizationCard: null,
        seaSlip: null,
        file: null,
        primaryColor: process.env.VUE_APP_THEME_COLOR_PRIMARY,
        colorDarken: process.env.VUE_APP_THEME_COLOR_DARKEN,
        schoolName: process.env.VUE_APP_THEME_SCHOOL,
        avatarColor: process.env.VUE_APP_THEME_COLOR_AVATAR,
        avatarDarken: process.env.VUE_APP_THEME_COLOR_AVATAR_DARKEN,
        studentDataFields: [],
        familyDataFields: [],        
        familyDemographicFields: [],
        otherDataFields: [],
        formProgress:{
            studentData: false,
            familyData: false,
            healthData: false,
            otherData: false,
            documents: false,
            submit: false,
        },
        termsAndConditions: false,          
    },


    getters: {
        getPrimaryColor(state){
            return state.primaryColor
        },
        getColorDarken(state){
            return state.colorDarken
        },
        getSchoolName(state){
            return state.schoolName
        },
        getAvatarColor(state){
            return state.avatarColor
        },
        getAvatarDarken(state){
            return state.avatarDarken
        },
        getStudentDataValidated(state){
            return state.studentDataValidated
        },

        getFamilyDataValidated(state){
            return state.familyDataValidated
        },

        getHealthDataValidated(state){
            return state.healthDataValidated
        },

        getOtherDataValidated(state){
            return state.otherDataValidated
        },

        getDocumentsAddedValidated(state){
            return state.documentsAddedValidated
        },

        getFormValidated(state){
            return state.formValidated
        },

        getCurrentStep(state){
            return state.currentStep
        },

        getTowns(state){
            return state.towns
        },

        getReligions(state){
            return state.religions
        },

        getEthnicGroups(state){
            return state.ethnicGroups
        },

        getPrimarySchools(state){
            return state.primarySchools
        },

        getHouse(state){
            return state.house
        },

        getDialogCard(state){
            return state.dialogCard
        },
        
        getDialog(state){
            return state.dialog
        },

        getDialogTitle(state){
            return state.dialogTitle
        },

        getDialogText(state){
            return state.dialogText
        },

        getDialogIcon(state){
            return state.dialogIcon
        },

        getDialogProgress(state){
            return state.dialogProgress
        },

        getDialogSuccessIcon(state){
            return state.dialogSuccessIcon
        },

        getDialogWarningIcon(state){
            return state.dialogWarningIcon
        },

        getValidationErrors(state){
            return state.validationErrors
        },

        getStudentDataMounted(state){
            return state.studentDataMounted
        },

        getFamilyDataMounted(state){
            return state.familyDataMounted
        },

        getHealthDataMounted(state){
            return state.healthDataMounted
        },

        getOtherDataMounted(state){
            return state.otherDataMounted
        },

        getUser(state){
            return state.user
        },

        getBirthCertificate(state){
            return state.birthCertificate
        },        

        getImmunization(state){
            return state.immunizationCard
        },

        getSeaSlip(state){
            return state.seaSlip
        },

        getFile(state){
            return state.file
        },

        getDialogTextColor(state){
            return state.dialogTextColor
        },

        getStudentDataFields(state){
            return state.studentDataFields
        },

        getFamilyDataFields(state){
            return state.familyDataFields
        },

        getFamilyDemographicFields(state){
            return state.familyDemographicFields
        },

        getOtherDataFields(state){
            return state.otherDataFields
        },

        getFormProgress(state){
            return state.formProgress
        },

        getTermsAndConditions(state){
            return state.termsAndConditions
        },

    },

    mutations: {
        setStudentDataValidated(state, value){
            state.studentDataValidated = value
        },

        setFamilyDataValidated(state, value){
            state.familyDataValidated = value
        },

        setHealthDataValidated(state, value){
            state.healthDataValidated = value
        },

        setOtherDataValidated(state, value){
            state.otherDataValidated = value
        },

        setDocumentsAddedValidated(state, value){
            state.documentsAddedValidated = value
        },

        setFormValidated(state, value){
            state.formValidated = value
        },

        setCurrentStep(state, value){
            state.currentStep = value
        },

        setTowns(state, value){
            state.towns = value
        },

        setReligions(state, value){
            state.religions = value
        },

        setEthnicGroups(state, value){
            state.ethnicGroups = value
        },

        setPrimarySchools(state, value){
            state.primarySchools = value
        },

        setHouse(state, value){
            state.house = value
        },        

        setDialog(state, value){
            state.dialog = value
        },

        setDialogCard(state, value){
            state.dialogCard = value
        },

        setDialogTitle(state, value){
            state.dialogTitle = value
        },

        setDialogText(state, value){
            state.dialogText = value
        },

        setDialogIcon(state, value){
            state.dialogIcon = value
        },

        setDialogTextColor(state, value){
            state.dialogTextColor = value
        },

        setDialogWarningIcon(state, value){
            state.dialogWarningIcon = value
        },

        setDialogSuccessIcon(state, value){
            state.dialogSuccessIcon = value
        },

        setDialogProgress(state, value){
            state.dialogProgress = value
        },

        setValidationErrors(state, value){
            state.validationErrors = value
        },

        setStudentDataMounted(state, value){
            state.studentDataMounted = value
        },

        setFamilyDataMounted(state, value){
            state.familyDataMounted = value
        },

        setHealthDataMounted(state, value){
            state.healthDataMounted = value
        },

        setOtherDataMounted(state, value){
            state.otherDataMounted = value
        },

        setUser(state, value){
            state.user = value
        },

        setBirthCertificate(state, value){
            state.birthCertificate = value
        },

        setImmunization(state, value){
            state.immunizationCard = value
        },

        setSeaSlip(state, value){
            state.seaSlip = value
        },

        setFile(state, value){
            state.file = value
        },

        setStudentDataFields(state, value){
            state.studentDataFields = value 
        },

        setFamilyDataFields(state, value){
            state.familyDataFields = value
        },

        setFamilyDemographicFields(state, value){
            state.familyDemographicFields = value
        },

        setOtherDataFields(state, value){
            state.otherDataFields = value
        },

        setFormProgress(state, value){
            state.formProgress = value
        },

        setTermsAndConditions(state, value){
            state.termsAndConditions = value
        },

        setdialogIconSuccess(state, value){
            state.dialogIconSuccess = value
        }
    },

    actions: {
        async retrieveTowns(){
            return await axios.get('/api/towns'); 
        },
        async retrieveReligions(){
            return await axios.get('/api/religions')
        },
        async retrieveEthnicGroups(){
            return await axios.get('/api/ethnic-groups')
        },
        async uploadBirthCertificate({state}){
            return await axios.post('/api/store-file',
                state.birthCertificate,
                {
                    headers: {
                        'Content-type' : 'multipart/form-data'
                    }
                }
            )
        },
        async uploadImmunization({state}){
            return await axios.post('/api/store-file',
                state.immunizationCard,
                {
                    headers: {
                        'Content-type' : 'multipart/form-data'
                    }
                }
            )
        },
        async uploadSeaSlip({state}){
            return await axios.post('/api/store-file',
                state.seaSlip,
                {
                    headers: {
                        'Content-type' : 'multipart/form-data'
                    }
                }
            )
        },
        async uploadFile({state}){
            return await axios.post('/api/store-file', 
                state.file,
                {
                    headers: {
                        'Content-type' : 'multipart/form-data'
                    }
                }
            )
        },


    }

}