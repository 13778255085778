import axios from 'axios'

export default{
    namespaced: true,
    state: {
        reportTerms: []
    },
    getters: {
        getReportTerms(state){
            return state.reportTerms
        }
    },
    mutations: {
        setReportTerms(state, value){
            state.reportTerms = value
        }
    },
    actions: {
        async getAcademicTerms({ commit }, payload){
            let url = '/api/student-reports/' + payload.id;
            try{
                const { data } = await axios.get(url);
                commit('setReportTerms', data);
            } catch (error){
                console.log(error);
            }
        }
    }
}