<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >  
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        FAMILY DATA
                    </v-toolbar-title>
                </v-app-bar>
            </v-card>
        </v-row>
        
        <v-row
            align="center"
            justify="center"
            class="mt-6"
        >
            <v-card
                class="pa-6" 
                width="500"
            >        
                <v-row 
                    align="center"
                    justify="center"                    
                >
                    <v-col
                        cols="12"                
                    >                        
                        <v-toolbar-title class="body-2 pl-0 text-wrap">
                            Please select Parents and or Guardian to enter information
                         </v-toolbar-title>      
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox
                                    v-model="visible.Mother"
                                    label="Mother"
                                    :color="themeColor"
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="visible.Father"
                                    label="Father"
                                    :color="themeColor"
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="visible.Guardian"
                                    label="Guardian"
                                    :color="themeColor"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        
        <v-row 
            align="center"
            justify="center"
            v-for="item in familyOptions"
            :key="item.option"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
                v-if="visibleSection(item.option)"                
            >
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        {{ item.option + "'s Information"}}
                    </v-toolbar-title>
                </v-app-bar>
                 <v-row 
                    align="center"
                    justify="center"
                    v-for="field in item.fields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                        
                    >                       
                        <v-text-field
                            v-if="field.required && field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"                                                       
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed'
                            ]"
                            autocomplete="false" 
                        ></v-text-field>

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"
                            :rules="[ 
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed'
                            ]"
                            autocomplete="false"
                        ></v-text-field>

                        <v-autocomplete
                            v-else-if="field.type == 'select' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"                        
                            @change="validate"
                            chips                            
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            autocomplete="false"                            
                        ></v-autocomplete>

                        <v-autocomplete
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"                        
                            @change="validate"
                            chips                            
                            autocomplete="false"                          
                        ></v-autocomplete>

                        <v-text-field
                            v-else-if="field.required && field.type == 'email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', 
                                () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' 
                            ]"                             
                            autocomplete="false"
                        ></v-text-field>

                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"                            
                            autocomplete="false"
                        ></v-text-field>

                        <v-text-field
                            v-else-if="!field.required && field.type == 'email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', 
                                () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' 
                            ]"                            
                            autocomplete="false"
                        ></v-text-field> 

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            autocomplete="false"
                        ></v-text-field>                        

                    </v-col>
                   
                </v-row>
            </v-card>            
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section2"
        >
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        Family Demographics
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in familyDemographicFields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >                          
                        <v-text-field
                            v-if="field.required && field.type == 'number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate"
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',                                
                            ]"
                            maxlength="2"
                            hint="Enter number only"
                            persistent-hint
                            autocomplete="false"
                        ></v-text-field>

                    </v-col>
                </v-row>
            </v-card>            
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section2"
        >
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >
                
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        Emergency Contact
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in emergencyContactFields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                            v-if="field.type == 'phone-number' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            maxlength="7" 
                            prefix="1868"
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed'
                            ]"
                            autocomplete="false" 
                        ></v-text-field>

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            maxlength="7" 
                            prefix="1868"
                            :rules="[ 
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed' 
                            ]"
                            autocomplete="false"
                        ></v-text-field> 
                        
                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            autocomplete="false"
                        ></v-text-field>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate"
                            autocomplete="false"                           
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'FamilyData',
    components: {
        Layout,
    },
    data () {
        return {
            familyOptions: [
                { 
                    option: 'Mother', 
                    visible: false,
                    fields: [
                        {label: 'Name', type: 'text', model: 'mother_name', required: true},                        
                        {
                            label: 'Maritial Status', 
                            type: 'select', 
                            items: ['Married', 'Single', 'Divorced', 'Widowed'],
                            model: 'mother_marital_status',
                            required: true
                        },
                        {label: 'Address', type: 'text', model: 'mother_home_address', required: true},
                        {label: 'Occupation', type: 'text', model: 'mother_occupation', required: true},
                        {label: 'Employer/Company', type: 'text', model: 'mother_business_place', required: true},
                        {label: 'Work Address', type: 'text', model: 'mother_business_address', required: false},
                        {label: 'Phone (Home) optional', type: 'phone-number', model: 'mother_phone_home', required: false},
                        {label: 'Phone (Work) optional', type: 'phone-number', model: 'mother_business_phone', required: false},
                        {label: 'Phone (Mobile)', type: 'phone-number', model: 'mobile_phone_mother', required: true},
                        {label: 'Email Address', type: 'email', model: 'email_mother', required: false},
                        {label: 'ID Card #', type: 'text', model: 'id_card_mother', required: true},
                    ]
                },
                { 
                    option: 'Father', 
                    visible: false,
                    fields: [
                        {label: 'Name', type: 'text', model: 'father_name', required: true},                        
                        {
                            label: 'Maritial Status', 
                            type: 'select', 
                            items: ['Married', 'Single', 'Divorced', 'Widowed'],
                            model: 'father_marital_status',
                            required: true
                        },
                        {label: 'Address', type: 'text', model: 'father_home_address', required: true},
                        {label: 'Occupation', type: 'text', model: 'father_occupation', required: true},
                        {label: 'Employer/Company', type: 'text', model: 'father_business_place', required: true},
                        {label: 'Work Address', type: 'text', model: 'father_business_address', required: false},
                        {label: 'Phone (Home)', type: 'phone-number', model: 'father_phone_home', required: false},
                        {label: 'Phone (Work)', type: 'phone-number', model: 'father_business_phone', required: false},
                        {label: 'Phone (Mobile)', type: 'phone-number', model: 'mobile_phone_father', required: true},
                        {label: 'Email Address', type: 'email', model: 'email_father', required: false},
                        {label: 'ID Card #', type: 'text', model: 'id_card_father', required: true},
                    ] 
                },
                { 
                    option: 'Guardian', 
                    visible: false,
                    fields: [
                        {label: 'Name', type: 'text', model: 'guardian_name', required: true},                        
                        {
                            label: 'Maritial Status', 
                            type: 'select', 
                            items: ['Married', 'Single', 'Divorced', 'Widowed'],
                            model: 'guardian_marital_status',
                            required: true
                        },
                        {label: 'Address', type: 'text', model: 'home_address_guardian', required: true},
                        {label: 'Occupation', type: 'text', model: 'guardian_occupation', required: true},
                        {label: 'Employer/Company', type: 'text', model: 'guardian_business_place', required: true},
                        {label: 'Work Address', type: 'text', model: 'guardian_business_address', required: false},
                        {label: 'Phone (Home)', type: 'phone-number', model: 'home_phone_guardian', required: false},
                        {label: 'Phone (Work)', type: 'phone-number', model: 'business_phone_guardian', required: false},
                        {label: 'Phone (Mobile)', type: 'phone-number', model: 'mobile_phone_guardian', required: true},
                        {label: 'Email Address', type: 'email', model: 'email_guardian', required: true},
                        {label: 'ID Card #', type: 'text', model: 'id_card_guardian', required: true},
                    ] 
                },                
            ],            
            otherFields: [
                {
                    label: 'Number of Children in Family',
                    type: 'number', 
                    model: 'no_in_family', 
                    required: true,
                    category: 'family_demographic',
                },            
                {
                    label: 'Number of Children at Home', 
                    type: 'number', 
                    model: 'no_at_home',
                    required: true,
                    category: 'family_demographic',
                },            
                {
                    label: 'Place in Family', 
                    type: 'number', 
                    model: 'place_in_family',
                    required: true,
                    category: 'family_demographic',
                },            
                {
                    label: 'Emergency Contact', 
                    type: 'text', 
                    model: 'emergency_contact',
                    placeholder: 'Enter name of an emergency contact',
                    required: true,
                    category: 'emergency_contact',
                },            
                {
                    label: 'Telephone Number', 
                    type: 'phone-number', 
                    model: 'emergency_home_phone',
                    required: true,
                    category: 'emergency_contact',
                },            
                {
                    label: 'Relation to Child', 
                    type: 'text', 
                    model: 'relation_to_child',
                    required: true,
                    category: 'emergency_contact',
                },            
                {
                    label: 'Workplace Number (optional)', 
                    type: 'phone-number', 
                    model: 'emergency_work_phone',
                    required: false,
                    category: 'emergency_contact',
                }, 
            ],
            studentRecord: {},
            parents: ['Mother', 'Father', 'Guardian'],
            parentsSelected: [],
            section2: false,
            visible:{
                Mother: false,
                Father: false,
                Guardian: false,
            },                                      
        }        
    },

    mounted: function () {
        this.setStudentRecord();
        this.setParentsSelected();
        this.familyDataFields();           
    },

    watch: {        
        visible: {
            deep: true,
            handler(){
                this.setVisibleParent();
            }
        }
    },

    computed: {
        visibleFamily(){
             return this.familyOptions.filter( field => field.visible == true);
        },
        regex(){
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },
        emergencyContactFields(){
            return this.otherFields.filter( field => field.category == 'emergency_contact');
        },
        familyDemographicFields(){
            return this.otherFields.filter( field => field.category == 'family_demographic');
        },
        ...mapGetters({
            getFamilyDataMounted: 'form/getFamilyDataMounted',
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
        }),
        themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
        },         
    },  
    
    methods: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord',            
        }),
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord()}
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setFamilyDataValidated: 'form/setFamilyDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setFamilyDataFields: 'form/setFamilyDataFields',
            setFamilyDemographicFields: 'form/setFamilyDemographicFields'          
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),
        updateStore(){
            console.log('updating store');
            this.updateStudentRecord(this.studentRecord);
            this.saveRecord()
                .then(response => {
                    console.log(response.status);
                });
        },        
        setVisibleParent(){
            this.section2 = true;
            this.familyOptions.forEach(familyOption => {
                familyOption.visible = this.visible[familyOption.option];
            });
            console.log(this.familyOptions);
            this.familyDataFields();  
        },        
        visibleSection(section){
            switch(section){
                case "Mother":
                    return this.visible.Mother;
                case "Father":
                    return this.visible.Father;
                case "Guardian":
                    return this.visible.Guardian;
            }            
        },
        setParentsSelected(){
            if(this.studentRecord.mother_name) {
                this.parentsSelected.push('Mother');
                this.familyOptions.forEach((parent, index) => {
                    if(parent.option == 'Mother'){
                        this.familyOptions[index].visible = true;                        
                    }
                })
            }
            if(this.studentRecord.father_name) {
                this.parentsSelected.push('Father');
                this.familyOptions.forEach((parent, index) => {
                    if(parent.option == 'Father'){
                        this.familyOptions[index].visible = true;                       
                    }
                })
            }
            if(this.studentRecord.guardian_name) {
                this.parentsSelected.push('Guardian');
                this.familyOptions.forEach((parent, index) => {
                    if(parent.option == 'Guardian'){
                        this.familyOptions[index].visible = true;                        
                    }
                })
            }
        },
        validate(){            
            let validationErrors = [];
            let formHasErrors = false;
            console.log('running validation...');
            console.log(this.familyOptions);
            this.familyOptions.forEach(familyOption => {
                console.log(familyOption.option);
                if(this.visible[familyOption.option]){
                    console.log(familyOption.fields);
                    familyOption.fields.forEach(field => {
                        if(field.required && !this.studentRecord[field.model]) {                    
                            console.log(`${familyOption.option} ${field.model} : false`);
                            formHasErrors = true;
                            validationErrors.push(`${familyOption.option} ${field.label} is required.`);
                        } 
                    }) 
                }                                            
                
            })
            console.log('checking other fields...');
            console.log(this.otherFields);
            this.otherFields.forEach(field => {
                if(field.required && !this.studentRecord[field.model]){
                    //console.log(`${field.model} : false`);
                    formHasErrors = true;
                    if(field.category == 'emergency_contact'){
                        validationErrors.push(`Emergency Contact ${field.label} is required.`);
                    }
                    else validationErrors.push(`${field.label} is required.`);
                }
            })

            console.log(`Form has errors: ${formHasErrors}`);

            if(!formHasErrors) this.setFamilyDataValidated(true);
            else this.setFamilyDataValidated(false);

            this.setValidationErrors(validationErrors);
            this.familyDataFields();
            this.updateStore();
        },
        familyDataFields(){
            this.setFamilyDataFields([...this.familyOptions]);
            this.setFamilyDemographicFields([...this.otherFields]);
        },
               
    }
}
</script>