<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        >
            <v-snackbar
                v-model="displayPasswordChanged"            
                elevation="10"
                top            
            >
                Password Changed Successfully.
                <template
                    v-slot:action="{ attrs }"
                >
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="setDisplayPasswordChanged(false)"
                    >close</v-btn>
                </template>
            </v-snackbar>
            <v-card            
                width="500"
            >  
                <v-card-title :class="cardTitleClass">
                    STUDENT INFORMATION
                </v-card-title>
                
            </v-card>
        </v-row>
        <v-row
            align="center"
            justify="center"
            v-for="section in sections"
            :key="section.label"            
        >
            <v-card
                class="pa-6 mt-5" 
                width="500"
                v-if="section.display"
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in sectionFields(section.number)"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                       
                    >                       

                        <v-combobox
                            v-if="field.type == 'select' && field.label == 'Town' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"
                            @blur="validateSection"
                            chips
                            autocomplete="none"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            :ref="field.ref"
                        ></v-combobox>

                        <v-combobox
                            v-if="field.type == 'select' && field.label == 'Town'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"
                            @blur="validateSection"
                            chips
                            autocomplete="none"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"                            
                            :ref="field.ref"
                        ></v-combobox>
                    
                        <v-autocomplete
                            v-else-if="field.type == 'select' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                        
                            @change="validateSection"
                            chips
                            autocomplete="false"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            :ref="field.ref"                           
                        ></v-autocomplete>

                        <v-autocomplete
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                        
                            @change="validateSection"
                            chips
                            autocomplete="false"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :disabled="field.disabled"
                            :filled="field.filled"                            
                            :ref="field.ref"                           
                        ></v-autocomplete>
                   
                        <v-row
                            v-else-if="field.type == 'switch'"
                            align="center"
                            justify="center"                            
                        >
                            <v-col cols="9">
                                {{ field.label }}
                            </v-col>

                            <v-col
                                class="d-flex"                        
                                cols="3"                    
                            >
                                <v-switch
                                    v-model="immigrationPermit"
                                    :label="setLabel(immigrationPermit)"
                                    @change="toggleImmigration"
                                    :ref="field.ref"                                    
                                ></v-switch>
                            </v-col>
                        </v-row> 

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"
                            autocomplete="none"
                            :ref='field.ref'
                            :rules="[() => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed']"                                                  
                        ></v-text-field>
                                          
                        <v-menu
                            v-else-if="field.type == 'date'"
                            ref="menuBirthDate"
                            v-model="calendarMenu[field.modelMenu]"
                            :close-on-content-click="false"                           
                            transition="scale-transistion"
                            offset-y
                            min-width="290px"                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentRecord[field.model]"
                                    :label="field.label"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autocomplete="false"
                                    :ref='field.ref'
                                    :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="studentRecord[field.model]"                                
                                @input="setDate(field.label)"
                            >                                
                            </v-date-picker>                            
                        </v-menu>

                        <v-text-field
                            v-else-if="field.required && field.label == 'Email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :ref="field.ref" 
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' ]"                           
                        ></v-text-field>

                        <v-text-field
                            v-else-if="field.label == 'Email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :ref="field.ref" 
                            :rules="[() => regex.test(studentRecord[field.model]) || 'Invalid e-mail' ]"                           
                        ></v-text-field>

                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :ref="field.ref" 
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"                           
                        ></v-text-field>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :ref="field.ref"                            
                        ></v-text-field>

                    </v-col>                
                </v-row>            
            </v-card>
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section7"                        
        >
            <v-card
                class="pa-6 mt-5" 
                width="500"                
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in sectionFields(7)"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                                                
                        <v-menu
                            v-if="field.type == 'date'"
                            ref="menu"
                            v-model="calendarMenu[field.modelMenu]"
                            :close-on-content-click="false"                           
                            transition="scale-transistion"
                            offset-y
                            min-width="290px"                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentRecord[field.model]"
                                    :label="field.label"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autocomplete="false"
                                    :ref='field.ref'                                    
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="studentRecord[field.model]"                                
                                @input="setDate(field.label)"
                            >                                
                            </v-date-picker>                            
                        </v-menu>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection"
                            :prepend-icon="field.icon"
                        ></v-text-field>
                    </v-col>                    
                </v-row>
            </v-card>                
        </v-row>

    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'StudentData',
    components: {
        Layout,
    },
    
    data: () => ({        
        fields: [
            { label: 'section 1', break: true, number: 1, display: true},
            {
                label: 'House', 
                model: 'house_code', 
                type: 'select', 
                disabled: true,
                filled: true,
                required: false,
                ref: 'house',
                items: [
                    {text: 'Tamana', value: '1'},
                    {text: 'Cumberland', value: '2'},
                    {text: 'Trinity', value: '3'},
                    {text: 'Chancellor', value: '4'},
                    {text: 'Cerro Aripo', value: '5'},
                    {text: 'El Tucuche', value: '6'},
                ],
                section: 1,
            },
            {
                label: 'Class', 
                model: 'class_id', 
                type: 'select', 
                disabled: true,
                filled: true,
                required: false,
                ref: 'class',
                items: [
                    { text: '1A', value: '1A'},
                    { text: '1B', value: '1B'},
                    { text: '1C', value: '1C'},
                ],
                section: 1,
            },
            { label: 'section 2', break: true, number: 2, display: true},
            {
                label: 'First Name', 
                model: 'first_name', 
                type: 'text',
                required: false,
                ref: 'first_name',
                section: 2,
            },
            {
                label: 'Last Name', 
                model: 'last_name', 
                type: 'text',
                required: false,
                ref: 'last_name', 
                section: 2,
            },
            {
                label: 'Other Name', 
                model: 'middle_name', 
                type: 'text',
                required: false,
                ref: 'middle_name', 
                section: 2,
            },
            {
                label: 'Gender', 
                model: 'sex', 
                type: 'select',
                items: [{text: 'Female', value: 'F'}, {text: 'Male', value: 'M'}],
                required: false,
                ref: 'sex',
                section: 2,                
            },
            {label: 'section 3', break: true, number: 3, display: true},
            {
                label: 'Address', 
                model: 'home_address', 
                type: 'text', 
                icon: 'mdi-home',
                required: false,
                ref: 'home_address', 
                section: 3
            },
            {
                label: 'Town', 
                model: 'address_line_2', 
                type: "select",
                items: [],
                text: 'town_name',
                value: 'town_name',
                placeholder: 'Type to search for town',
                icon: 'mdi-city',
                required: false,
                ref: 'address_line_2',
                section: 3,
            },
            {
                label: 'Phone Number (Mobile)', 
                model: 'phone_cell', 
                type: 'phone-number', 
                icon: 'mdi-cellphone',
                required: false,
                section: 3,
            },
            {
                label: 'Email', 
                model: 'email', 
                type: "email", 
                icon: 'mdi-email',
                required: false,
                section: 3,
            },
            {
                label: 'Blood Group', 
                model: 'blood_type', 
                type: "select",
                items: [
                    'O-positive',
                    'O-negative',
                    'A-positive',
                    'A-negative',
                    'B-positive',
                    'B-negative',
                    'AB-positive',
                    'AB-negative'
                ],
                placeholder: 'Type to search for blood group',
                icon: 'mdi-water',
                required: false,                
                section: 3,
            },
            {label: 'section 4', break: true, number: 4, display: true},
            {
                label: 'Previous School', 
                model: 'previous_school', 
                type: 'text',
                required: false,
                section: 4,
            },
            {
                label: 'SEA Number', 
                model: 'sea_no', 
                type: 'text',
                required: false,
                section: 4,
            },
            {
                label: 'Religion', 
                model: 'religion_code', 
                type: 'select',
                items: [],
                text: 'religion',
                value: 'code',
                required: false,
                section: 4,
            },
            {
                label: 'Ethnic Group', 
                model: 'ethnic_group_code', 
                type: 'select',
                items: [],
                text: 'group_type',
                value: 'id',
                required: false,
                section: 4,
            },
            {label: 'section 5', break: true, number: 5, display: true},            
            {
                label: 'Electronic Birth Pin', 
                model: 'birth_certificate_no', 
                type: 'text', 
                icon: 'mdi-dialpad',
                required: false,
                section: 5,
            },            
            {
                label: 'Date of Birth', 
                model: 'date_of_birth',
                modelMenu: 'menuBirthDate',
                type: 'date', 
                icon: 'mdi-calendar',
                required: false,
                section: 5,
            },            
            {
                label: 'Country of Birth', 
                model: 'place_of_birth', 
                type: 'text', 
                icon: 'mdi-earth',
                required: false,
                section: 5,
            },
            {
                label: 'Nationality', 
                model: 'nationality', 
                type: 'text', 
                icon: 'mdi-flag',
                required: false,
                section: 5,
            },
            {label: 'section 6', break: true, number: 6, display: true},           
            {
                label: 'Do you have an Immigration Student Permit', 
                model: 'immigration_permit', 
                type: 'switch',
                required: false,
                section: 6,
            },
            {label: 'section 7', break: true, number: 7, display: false},   
            {
                label: 'Date of Issue', 
                model: 'permit_issue_date',
                modelMenu: 'menuIssueDate', 
                type: 'date',
                required: false,
                section: 7,
                display: false,
            },
            {
                label: 'Expiry Date', 
                model: 'permit_expiry_date',
                modelMenu: 'menuExpiryDate', 
                type: 'date',
                section: 7,
                required: false,
                display: false,
            },            
            {
                label: 'Second Language', 
                model: 'second_language', 
                type: 'text',
                icon: 'mdi-earth',
                section: 7,
                required: false,
                display: false,
            },
        ],        
        studentRecord: {},
        section7: false,
        immigrationPermit: false,        
        menu: false,        
        calendarMenu: {
            menuBirthDate: false,
            menuIssueDate: false,
            menuExpiryDate: false,
        },
        date: null,
        sectionValid: false,
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            required: value => !!value || 'This field is required.'
        },
        snackbar: true,
    }),

    mounted: function () {
        console.log('Mounted');
        console.log(this.studentRecord);
        this.setStudentRecord();
        console.log(this.studentRecord);
        this.loadTowns();
        this.loadReligions();
        this.loadEthnicGroups();
        this.setStudentDataFields();
        this.setDialog(false);
    },
    
    computed: {        
        ...mapGetters({
            getTowns: 'form/getTowns',
            getReligions: 'form/getReligions',
            getEthnicGroups: 'form/getEthnicGroups',
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
            displayPasswordChanged: 'auth/getDisplayPasswordChanged',
        }),
        sections(){
            return this.fields.filter( field => field.break == true);
        },
        themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
        },
        regex(){
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },
        cardTitleClass(){
            return "white--text pl-4 " + this.themeColor;
        }                            
    },

    watch: {
        immigrationPermit: {
            handler(newValue){
                this.section7 = newValue;
            }
        },
        
    },

    methods: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord',            
        }),
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord()}            
            this.setUser(this.studentRecord.first_name.charAt(0)+this.studentRecord.last_name.charAt(0));
            if(this.studentRecord.immigration_permit == 1){
                this.immigrationPermit = true;
            }
            else{
                this.immigrationPermit = false;
            }
            if(this.studentRecord.terms_and_conditions == 0) this.setTermsAndConditions(false);
            else this.setTermsAndConditions(true);
            this.studentRecord.ethnic_group_code = parseInt(this.studentRecord.ethnic_group_code);
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setTowns: 'form/setTowns',
            setReligions: 'form/setReligions',
            setEthnicGroups: 'form/setEthnicGroups',
            setStudentDataValidated: 'form/setStudentDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setUser: 'form/setUser',
            studentDataFields: 'form/setStudentDataFields',
            setTermsAndConditions: 'form/setTermsAndConditions',
            setDialog: 'form/setDialog',
            setDisplayPasswordChanged: 'auth/setDisplayPasswordChanged',            
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
            retrieveTowns: 'form/retrieveTowns',
            retrieveReligions: 'form/retrieveReligions',
            retrieveEthnicGroups: 'form/retrieveEthnicGroups'
        }),
        updateStore(){
            //console.log(this.studentRecord);            
            this.updateStudentRecord(this.studentRecord);            
            this.saveRecord()
                .then(response => {
                    console.log(response.status);
                });
        },
        loadTowns(){
            this.retrieveTowns()
                .then(response => {                   
                    this.setTowns(response.data);
                    this.fields[10].items = this.getTowns;
                })
        },
        loadReligions(){
            this.retrieveReligions()
                .then(response => {
                    console.log(response);
                    this.setReligions(response.data);
                    this.fields[17].items = this.getReligions;
                })
        },
        loadEthnicGroups(){
            this.retrieveEthnicGroups()
                .then(response => {
                    this.setEthnicGroups(response.data);
                    this.fields[18].items = this.getEthnicGroups;
                })
        },
        sectionFields(number){
            return this.fields.filter( field => field.section == number);
        },
        setLabel(label){
            return label ? 'Yes' : 'No';
        },       
        toggleImmigration(){            
            if(this.immigrationPermit){
                this.studentRecord.immigration_permit = 1;
            }
            else{
                this.studentRecord.immigration_permit = 0;
            }
            this.validateSection();
        },        
        setDate(menu){            
            switch(menu){
                case 'Date of Birth':
                    this.calendarMenu.menuBirthDate = false;
                    break;
                case 'Expiry Date':
                    this.calendarMenu.menuExpiryDate = false;
                    break;
                case 'Date of Issue':
                    this.calendarMenu.menuIssueDate = false;
                    break;        
            }                     
            this.validateSection();
        }, 
        validateSection(){            
            let sectionFields = this.fields.filter( field => field.break !== true);
            let validationErrors = [];
            let formHasErrors = false;
            console.log('Validating...');
            console.log(this.studentRecord);
            
            sectionFields.forEach(element => {
                if(element.required && !this.studentRecord[element.model]) {                    
                    console.log(`${element.model} : false`);
                    formHasErrors = true;
                    validationErrors.push(`${element.label} is required.`);
                }               
                
                if(!formHasErrors) this.setStudentDataValidated(true);
                else this.setStudentDataValidated(false);
            })

            this.setValidationErrors(validationErrors);
            this.updateStore();
        },
        setStudentDataFields(){
            let sectionFields = this.fields.filter( field => field.break !== true);
            this.studentDataFields(sectionFields);
        },
        closeSnackbar(){
            this.setDisplayPasswordChanged(false);
        },
        numberOnly($event){            
            if(!/\d/.test($event.key)) return $event.preventDefault();
        }
              
    },
    
}
</script>