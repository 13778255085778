<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >  
                <v-app-bar
                    flat
                    :color="themeColor"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        OTHER DATA
                    </v-toolbar-title>
                </v-app-bar>
                
            </v-card>
        </v-row>

        <v-row 
            align="center"
            justify="center"
            v-for="field in section"
            :key="field.label"            
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
                v-if="field.type == 'text'"
            >
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                           :label="field.label"
                           v-model="studentRecord[field.model]"
                           @blur="validate"                           
                           clearable
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

            <v-card
                class="pa-6 mt-4" 
                width="500"
                v-if="field.type == 'select'"
            >
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-select
                            :items="field.options"
                            :label="field.label"
                            v-model="studentRecord[field.model]"
                            @change="validate"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            clearable
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>      

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >                 
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in filterSwitchFields"
                    :key="field.label"
                >
                    <v-col cols="8">
                        {{ field.label }}
                    </v-col>

                    <v-col
                       class="d-flex"
                       v-if="field.type == 'switch'"
                       cols="4"                    
                    >
                        <v-switch
                            v-model="switchFields[field.model]"
                            :label="setLabel(switchFields[field.model])"
                            @change="switchFieldsUpdate"
                        ></v-switch>
                    </v-col>                    
                </v-row>
            </v-card>                      
         </v-row>
                 
    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'OtherData',
    components: {
        Layout,
    },

    mounted: function () {
        this.setStudentRecord();
        this.setSwitchFields();
        this.otherDataFields();        
    },
    
    data: () => ({
        fields: [
            {label: 'Extra-Curricular Interests', type: 'text', model: 'achivements', required: false},
            {
                label: 'What type of device does your child have access to?', 
                options: ['No Device', 'Phone', 'Laptop', 'Desktop', 'Tablet', 'Other'],
                type: 'select',
                model: 'device_type',
                required: true,
            },
            {
                label: 'Does your child have internet access?', 
                type: 'switch', 
                model: 'internet_access',
                required: false,
            },
            {
                label: 'Would your child require School Feeding Meals?', 
                type: 'switch', 
                model: 'school_feeding',
                required: false,
            },
            {
                label: 'Does your child access Social Welfare Grant?', 
                type: 'switch', 
                model: 'social_welfare',
                required: false,
            },
            {
                label: 'Does your child access School Transport?', 
                type: 'switch', 
                model: 'school_transport',
                required: false,
            },                        
            {
                label: 'Would you be requesting a transfer?', 
                type: 'switch', 
                model: 'request_transfer',
                required: false
            },            
        ],
        studentRecord: {},
        switchFields: {
            school_feeding: false,
            social_welfare: false,
            school_transport: false,
            internet_access: false,
            request_transfer: false,
        }
    }),

    computed: {
        section(){
            return this.fields.filter( field => field.type == 'text' || field.type == 'select');
        },
        filterSwitchFields(){
            return this.fields.filter( field => field.type == 'switch');
        },
        ...mapGetters({            
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
        }),
        themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
        },           
    },

    methods: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord'
        }),
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord()}
        },
        setSwitchFields(){
            for(const [key, value] of Object.entries(this.switchFields)){
                console.log(value);               
               if(this.studentRecord[key] == 1){
                   this.switchFields[key] = true;
               }
               else{
                   this.switchFields[key] = false;
               }                           
           }
           //console.log(this.switchFields);
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setOtherDataValidated: 'form/setOtherDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setOtherDataFields: 'form/setOtherDataFields',            
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),
        updateStore(){
            console.log('updating store');
            this.updateStudentRecord(this.studentRecord);
            console.log(this.studentRecord);
            this.saveRecord()
                .then(response => {
                    console.log(response.status);
                });
        },
        switchFieldsUpdate(){
           for(const [key, value] of Object.entries(this.switchFields)){
               //console.log(`${key}: ${value}`)
               console.log(value);
               if(value){
                   this.studentRecord[key] = 1;
               }
               else{
                   this.studentRecord[key] = 0;
               }
           }
           this.updateStore();
        },
        setLabel(label){
            return label ? 'Yes' : 'No';
        },
        validate(){
            console.log('Validating...');            
            let validationErrors = [];
            let formHasErrors = false;
            
            this.fields.forEach(field => {
                //console.log(this.visible);
                if(field.required && !this.studentRecord[field.model]) {                    
                    console.log(`${field.model} : false`);
                    formHasErrors = true;
                    validationErrors.push(`${field.label} is required.`);
                }                                           
                
            })            

            if(!formHasErrors) this.setOtherDataValidated(true);
            else this.setOtherDataValidated(false);

            this.setValidationErrors(validationErrors);
            this.updateStore();
        },
        otherDataFields(){
            this.setOtherDataFields([...this.fields]);
        }
    }
}
</script>