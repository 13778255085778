<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >
                <v-row>
                    <p class="font-weight-light">
                        I agree with and will abide by the school’s policies, 
                        procedures and rules which are clearly identified 
                        in the School’s Prospectus including the 
                        Standards of Behaviour for Online learning. 
                        I will make every effort to have my child uphold 
                        the Ministry's School Code of Conduct and Rules of the School
                    </p>
                    <v-checkbox
                        label="I Agree"
                        v-model="agree"                        
                    ></v-checkbox>
                </v-row>

                <v-row 
                    align="center"
                    justify="center"
                    v-show="printButton"                   
                >
                    <v-col
                        cols="12"
                        class="pl-0"                        
                    >
                        <v-btn
                            dark
                            color="cyan darken-3"
                        >                            
                            <v-icon left>mdi-printer</v-icon>
                            Print Registration Form
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>     
    </layout>
</template>

<script>
import Layout from './layouts/App';
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Complete',
    components: {
        Layout,
    },
    mounted: function(){
        this.setStudentRecord();
        this.setAgree();
    },
    data: () => ({
        printButton: false,        
        studentRecord: {},
        agree: false,
    }),
    watch: {
        agree: {
            handler(val){
                console.log(`Agree: ${val}`);
                this.setTermsAndCondtions(val);                
                this.updateStore();
            }
        }
    },    
    computed: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord',
            termsAndConditions: 'form/getTermsAndConditions',
        }),
        getAgree(){
            if(this.termsAndConditions == 1) return true;
            return false;
        }
    },
    methods: {
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setTermsAndCondtions: 'form/setTermsAndConditions',
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),
        updateStore(){
            if(this.agree){
                this.studentRecord.agree_terms_conditions = 1;
            }
            else{
                this.studentRecord.agree_terms_conditions = 0;
            }            
            this.updateStudentRecord(this.studentRecord);
            this.saveRecord()
                .then(response => {
                    console.log(response.status);
                });
        },
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord}            
        },
        
        setAgree(){
            console.log(this.getAgree);
            if(this.getAgree == 1) this.agree = true;
            else this.agree = false;
        }
    }
}
</script>